<template>
  <div class="admin-reports">
    <winbox-company-header
      title="Reports"
      class="main-title"
    />
    <winbox-data-table
      class="main-data-table"
      :data="dataset"
      :columns="columns"
      :options="tableOptions"
      :slots="specificSlots"
    >
      <template :slot="tableProps.name" slot-scope="data">
        {{ data.row[tableProps.name] }}
      </template>
      <template :slot="tableProps.last_run" slot-scope="data">
        {{ data.row[tableProps.last_run] }}
      </template>
      <template :slot="tableProps.date_range" slot-scope="data">
        <winbox-input
          v-if="data.row.takes_dates"
          v-model="data.row[tableProps.date_range]"
          placeholder="Input a date range to run a report"
          :date-picker="true"
          :date-range="true"
          :max-date="today"
          orientation="top"
        />
      </template>
      <template :slot="tableProps.run" slot-scope="data">
        <button
          class="button button-edit"
          @click="createReport(data.row)"
        >
          <winbox-icon
            icon="download"
            :color="Colors.system.primaryLight"
          />
        </button>
      </template>
    </winbox-data-table>
  </div>
</template>

<script>
import * as ReportsApi from "@/api/reports/reports";

import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxInput from "@/components/Atoms/WinboxInput";

import { DateFormats } from "@/constants/DateFormats";

import { Colors } from "@/Colors";

import moment from "moment";

const tableProps = {
  name: "name",
  last_run: "last_run",
  date_range: "date_range",
  run: "run",
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "AdminReports",
  components: {
    WinboxCompanyHeader,
    WinboxDataTable,
    WinboxIcon,
    WinboxInput
  },
  validations: {},
  data() {
    return {
      Colors: Colors,
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.name]: "Name",
          [tableProps.last_run]: "Last Run",
          [tableProps.date_range]: "Next Run Range",
          [tableProps.run]: "Run"
        },
        orderBy: { column: [tableProps.name] },
        sortable: [tableProps.name, tableProps.last_run],
        filterable: [tableProps.name, tableProps.last_run]
      },
      specificSlots: [...mainTableColumns]
    };
  },
  computed: {
    dataset() {
      return this.formatData(this.allReports);
    },
    allReports() {
      return this.$store.getters.REPORTS;
    },
    today() {
      return moment().format(DateFormats.BASE);
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.$store.dispatch("getReports");
    },
    formatData(data) {
      const result = data.map(report => {
        let display_lr = report.last_run;
        if (!!display_lr && !display_lr.includes("Never")) {
          display_lr = display_lr.substr(0, display_lr.lastIndexOf('.')).replace(/T/g, ' ');
        }
        return {
          name: report.name,
          last_run: display_lr,
          background_job: report.background_job,
          takes_dates: report.takes_dates
        };
      });
      return result;
    },
    createReport(row) {
      if (!row.takes_dates || (row.date_range && row.date_range !== "")) {
        if (row.background_job) {
          ReportsApi.createReport(row)
            .then(() => {
              alert("Your report will be generated in the background and sent via email.");
            })
            .catch(error => {
              alert("There was an issue starting the background job. Please try again.");
            });
        } else {
          ReportsApi.createReport(row).then(createResponse => {
            const csvContent = createResponse.data;
            const cd = createResponse.headers["content-disposition"];
            const frontTrimmedFilename = cd.substring(cd.indexOf("filename=\"") + 10);
            const filename = frontTrimmedFilename.substring(0, frontTrimmedFilename.indexOf(".csv") + 4);
            this.csvExport(filename, csvContent);
          }).catch(error => {
            alert("Error generating the report. Please try again.");
          });
        }
      } else {
        alert("Please select a date range before generating the report.");
      }
    },
    csvExport(filename, csvContent) {
      const content = encodeURI("data:text/csv;charset=utf-8," + csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", content);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style lang="scss" scoped>
.main-title {
  margin: 0 0 1rem;
}
</style>
