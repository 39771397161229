export const DemographicAllowedValues = {
  brand_focus: ['B2B', 'B2C', 'DTC', 'HCP', 'DTP', 'B2G', 'B2E'],
  gender: ['Male', 'Female'],
  region: ['Northeast', 'Midwest', 'South', 'West', 'Southeast', 'Southwest', 'Northwest', 'N/A'],
  region_type: ['City', 'Urban', 'Suburban', 'Rural', 'Town'],
  age: ['18-24', '25-34', '35-44', '45-54', '55+'],
  generation: ['Silent Generation', 'Baby Boomers', 'Generation X', 'Millennials (Y)', 'Generation Z', 'Generation Alpha'],
  ethnicity: ['White / Caucasian', 'African / Black', 'Hispanic / Latino', 'Asian', 'Indigenous / Native', 'Middle Eastern / North African', 'Pacific Islander', 'Mixed Ethnicity / Multiracial'],
  household_income: ['$0 - 25k', '$25 - 50k', '$50 - 100k', '$100k - 150k', '$150k - 200k', '$200k+'],
  marital_status: ['Single', 'Married', 'Separated', 'Divorced', 'Widowed', 'Civil Union'],
  life_stage: ['Single', 'Young Couple (No Children)', 'Young Family (Children under 6)', 'Established Family (Children 6-17)', 'Empty Nesters (Children Grown)', 'Single Parent', 'Retired / Senior', 'Student', 'Career-Oriented Individuals (No Children)', 'Widowed / Living Alone']
};
