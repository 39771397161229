import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";
import { itemList as locationData } from "@/test/data/locations";
import {
  singleItem as agencyDataObj,
  itemList as listAgencies
} from "@/test/data/business";
import moment from "moment";
import { DateFormats } from "@/constants/DateFormats";
import Vue from 'vue';

export const EventBus = new Vue();

export function sortAlphabetic(items, prop) {
  items.sort(function (a, b) {
    let nameA = a[prop] ? a[prop].toUpperCase() : "";
    let nameB = b[prop] ? b[prop].toUpperCase() : "";
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return items;
}

export function sortNumericByPropComplex(items, prop, desc = false) {
  const result = items.sort(function (a, b) {
    if (!a[prop]) return 1;
    if (!b[prop]) return -1;

    return a[prop] - b[prop];
  });

  if (desc) {
    result.reverse();
  }

  return result;
}

export function filterByProp(data, searchVal, propName) {
  const result = [];

  data.forEach(item => {
    if (typeof item[propName] === "string") {
      const regex = new RegExp(searchVal, "i");
      if (regex.test(item[propName])) {
        result.push(item);
      }
    }
  });

  return result;
}

export function findUserName(id, users) {
  let result = "";
  const found = users.find(user => user.id === id);
  if (found) {
    result = `${found.first_name} ${found.last_name}`;
  }
  return result;
}

export function findStatusForSelect(value, data) {
  return data.find(item => item.value === value);
}

export function randomId() {
  return (
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  );
}

export function spliceArrayItem(items, data) {
  return items.map(item => {
    if (item.id === data.id) {
      return { ...data };
    } else {
      return item;
    }
  });
}

export function winboxValidGenericValidator(value, length) {
  let result = false;
  const intValue = parseInt(value);
  const asString = intValue ? intValue + "" : "";

  if (!value) {
    result = true;
  } else if (typeof length === "number") {
    if (asString.length === length) {
      result = true;
    }
  } else if (typeof Array.isArray(length)) {
    if (
      asString.length >= length[0] &&
      asString.length <= length[1] &&
      value.length === asString.length
    ) {
      result = true;
    }
  }

  return result;
}

export function winboxValidYear(value) {
  return winboxValidGenericValidator(value, 4);
}

export function winboxValidPhone(value) {
  return value == null || value.length == 0 || value.length == 14;
}

export function winboxValidUrl(value) {
  let expression = /(?:(^(?!(www\.))[-a-zA-Z0-9]*\.[a-zA-Z]{1,6}|(^www\.[-a-zA-Z0-9]*\.[a-zA-Z]{1,6})|(?:(^https:\/\/|^http:\/\/)[-a-zA-Z0-9()@:%_\+.~#?&/=]*)))/;
  let regex = new RegExp(expression);
  let regexResult = value?.match(regex)
  return value == null || regexResult != null && regexResult[4] == null;
}

export function winboxValidSocialUrl(value) {
  let expression = /^(https?:\/\/)/;
  let regex = new RegExp(expression);
  let regexResult = value?.match(regex);
  return value == null || regexResult != null;
}

export function getServerDateFormat(date = new Date()) {
  return moment.utc(date).format(DateFormats.SERVER);
}

export function getYearDateFormat(date) {
  return date ? moment.utc(date).format(DateFormats.YEAR_ONLY) : date;
}

export function prepareDefaultYearsFrom(
  currentYear,
  plusNextYears = 0,
  maxYears = 10
) {
  let year = currentYear + plusNextYears;
  let result = [...Array(maxYears).keys()];

  result = result.map(item => year--);
  return result;
}

export function validationStatus(validation) {
  return {
    error: validation.$error,
    dirty: validation.$dirty,
    invalid: validation.$invalid,
  };
}

export function makeArrayToString(data, key = "name", sep = ", ") {
  let result = [];

  if (data && data.length) {
    result = data.map(item => item[key]);
  }
  return result.join(sep);
}

export function getCompaniesPage(params = "") {
  return `${WinboxRoutePaths.COMPANIES}${params}`;
}
export function getCompanyProfileUrl(id) {
  return `${WinboxRoutePaths.COMPANIES}/${id}/${WinboxRoutePaths.COMPANY_PROFILE}`;
}
export function getCompanyLocationsUrl(id) {
  return `${WinboxRoutePaths.COMPANIES}/${id}/${WinboxRoutePaths.COMPANY_LOCATIONS}`;
}
export function getCompanyLocationDetailUrl(companyId, locationId) {
  return `${WinboxRoutePaths.COMPANIES}/${companyId}/${WinboxRoutePaths.COMPANY_LOCATIONS}/${locationId}`;
}

export function getAgenciesPage(params = "") {
  return `${WinboxRoutePaths.AGENCIES}${params}`;
}
export function getAgencyProfileUrl(id) {
  return `${WinboxRoutePaths.AGENCIES}/${id}/${WinboxRoutePaths.AGENCY_PROFILE}`;
}
export function getAgencyLocationsUrl(id) {
  return `${WinboxRoutePaths.AGENCIES}/${id}/${WinboxRoutePaths.AGENCY_LOCATIONS}`;
}
export function getAgencyLocationDetailUrl(companyId, locationId) {
  return `${WinboxRoutePaths.AGENCIES}/${companyId}/${WinboxRoutePaths.AGENCY_LOCATIONS}/${locationId}`;
}

export function getBrandsPage(params = "") {
  return `${WinboxRoutePaths.BRANDS}${params}`;
}
export function getBrandProfileUrl(id) {
  return `${WinboxRoutePaths.BRANDS}/${id}/${WinboxRoutePaths.BRAND_PROFILE}`;
}

export function getContactsPage(params = "") {
  return `${WinboxRoutePaths.CONTACTS}${params}`;
}
export function getContactProfileUrl(id) {
  return `${WinboxRoutePaths.CONTACTS}/${id}/${WinboxRoutePaths.CONTACT_PROFILE}/${WinboxRoutePaths.CONTACT_PROFILE_TITLE_FUNCTIONS}`;
}

export function getDefaultChartOptions() {
  return {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            display: true,
            autoSkip: true,
            maxTicksLimit: 6
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };
}

export function chartDatasetTemplate(label, data = []) {
  return {
    label: label,
    fill: false,
    backgroundColor: "#00C0B5",
    borderColor: "#007BFF",
    borderWidth: 1,
    pointBorderColor: "#00C0B5",
    data: data
  };
}

export function dummyChartDataset() {
  return [
    {
      label: "My First dataset",
      fill: false,
      backgroundColor: "#00C0B5",
      borderColor: "#007BFF",
      borderWidth: 1,
      pointBorderColor: "#00C0B5",
      data: [33, 15, 3, 35, 60, 89]
    },
    {
      label: "My Second dataset",
      fill: false,
      backgroundColor: "#f87979",
      borderColor: "#007BFF",
      pointBorderColor: "#f87979",
      borderWidth: 1,
      data: [0, 44, 100, 55, 33, 75]
    }
  ];
}

export function dummyBarChartDataset() {
  return [
    {
      label: "Value",
      backgroundColor: "#00C0B5",
      borderColor: "#00C0B5",
      data: [220, 245, 687, 224, 550]
    }
  ];
}

export function defaultTableDataset() {
  return [
    {
      active: true,
      id: 64333,
      type: "company",
      name: "__MOCK__: BMW of North America, LLC",
      org_type: "public_type",
      location: "Woodcliff Lake",
      _location_id: 1,
      phone: "201-307-4000",
      address: "300 Chestnut Ridge Road",
      founded: "1976",
      fiscal: "December",
      long_description:
        "Established in 1975, BMW of North America is a leading importer of luxury and performance vehicles. MINI Cooper and Rolls-Royce automobiles are also imported and marketed by the company.  BMW of North America is headquartered in Woodcliff Lake, NJ.",
      tags: defaultSpecialities()
        .slice(0, 2)
        .map(item => {
          return { ...item, year: 2017 };
        })
    },
    {
      id: 23223,
      name: "__MOCK__: Ford Motor Company",
      type: "company",
      org_type: "private_type",
      location: "Dearborn",
      _location_id: 2,
      phone: "302-401-5000",
      address: "",
      founded: "1933",
      fiscal: "November",
      long_description:
        "Ford Motor Company is a manufacturer of cars and trucks. The company and its subsidiaries also engage in other businesses, including financing vehicles.",
      tags: defaultSpecialities()
        .slice(2, 4)
        .map(item => {
          return { ...item, year: 2018 };
        })
    }
  ];
}

export function defaultBrands() {
  return [
    {
      id: 8888888,
      name: "brands test",
      location: "New York",
      _location_id: 1,
      phone: "(214) 405-4617"
    }
  ];
}

export function defaultCompanyContacts() {
  return [
    {
      company_id: 643,
      type: "Contact",
      date_created: "2005-09-30",
      entity_type: null,
      fname: "__MOCK__: David",
      title: "Chief Executive Officer & Managing Partner - Google Ventures",
      title_rank: "Chief",
      lname: "Krane",
      name: "__MOCK__: David Krane",
      email: "not_real_gmail1111@google.com",
      direct_phone: "555-1212 ext.23",
      extension_phone: "513",
      parent_company: "Google, Inc.",
      id: 58010,
      person_type: "company",
      state: "CA",
      status: "",
      business: "BMW of North America, LLC",
      agency: "MindShare North",
      organization: "",
      location: "Atlanta, Georgia",
      scope: "Regional",
      provided: true,
      verified: null,
      logoUrl: "https://material-ui.com/static/images/avatar/1.jpg"
    },
    {
      company_id: 232,
      type: "Contact",
      date_created: "2006-12-15",
      entity_type: null,
      fname: "__MOCK__: David",
      title: "Partner - Google Capital",
      title_rank: "",
      lname: "Lawee",
      name: "__MOCK__: David Lawee",
      email: "not_real_gmail2222@google.com",
      direct_phone: "(513) 634-3123",
      parent_company: "Google, Inc.",
      id: 150820,
      person_type: "company",
      state: "CA",
      status: "",
      business: "Ford Motor Company",
      agency: "MindShare South",
      organization: "",
      location: "Atlanta, Georgia",
      scope: "",
      provided: null,
      verified: null,
      logoUrl: null
    }
  ];
}

export function defaultAgencies(moreAgencies = false) {
  let result = [agencyDataObj];
  if (moreAgencies) {
    result = [...listAgencies];
  }
  return result;
}
export function defaultCompanyAgencies() {
  return [
    {
      company_id: 643,
      companyar_agency_id: 61325,
      companyar_agency_name: "Anchor Worldwide",
      companyar_agency_type: "",
      companyar_brand: "Rolls-Royce Motor Cars",
      companyar_brand_id: 67437,
      companyar_created: "2019-10-17",
      companyar_from: "2019",
      companyar_service: "Social",
      companyar_services: ["AOR", "Social"],
      companyar_status: "",
      companyar_to: "present",
      companyar_unique: 32271,
      companyar_updated: "2019-10-17",
      is_aor: true
    },
    {
      company_id: 643,
      companyar_agency_id: 15225,
      companyar_agency_name: "Universal McCann",
      companyar_agency_type: "",
      companyar_brand: "BMW 5 Series",
      companyar_brand_id: 40990,
      companyar_created: "2011-08-10",
      companyar_from: "2009",
      companyar_service: "media buying & planning",
      companyar_services: ["AOR", "Media Buying", "Media Planning"],
      companyar_status: "",
      companyar_to: "present",
      companyar_unique: 11043,
      companyar_updated: "2019-10-21",
      is_aor: true
    }
  ];
}
export function defaultCompanyBrands() {
  return [
    {
      id: 9,
      brand_id: 9,
      city_hq: "Woodcliff Lake",
      location: "Woodcliff Lake, NJ",
      type: "Brand",
      company_id: 643,
      company_media_spend: 69178040,
      companybrand_id: 4,
      description: "automotive, sedan",
      companybrand_industries: "Automotive - manufacturers",
      companybrand_status: "",
      entity_id: 4,
      entity_type: "brand",
      hookit: 0,
      inhouse_creative: false,
      inhouse_media: true,
      media_spend: 153450,
      name: "__MOCK__: BMW 7 Series",
      parent_company: "BMW of North America, LLC",
      services: ["AOR", "Media Buying", "Media Planning"],
      mediaTypes: ["Some media type 3"],
      revenue: 0,
      state_hq_name: "NJ",
      website_for_ui: "",
      from: "",
      to: ""
    },
    {
      id: 13,
      brand_id: 13,
      city_hq: "Woodcliff Lake 2",
      type: "Brand",
      location: "Woodcliff Lake 2, NJ",
      company_id: 555,
      company_media_spend: 2132312,
      companybrand_id: 4,
      description: "automotive, sedan",
      companybrand_industries: "Automotive - manufacturers",
      companybrand_status: "",
      entity_id: 5,
      entity_type: "brand",
      hookit: 0,
      inhouse_creative: true,
      inhouse_media: false,
      media_spend: 23123,
      name: "__MOCK__: BMW 7 Series 222",
      parent_company: "BMW of North America, LLC",
      services: ["Media Planning"],
      mediaTypes: ["Some media type 4"],
      revenue: 0,
      state_hq_name: "NJ",
      website_for_ui: "",
      from: "",
      to: ""
    }
  ];
}
export function defaultLocations() {
  return [...locationData];
}
export function tempPossibleLocations() {
  return [
    {
      parentId: 64333,
      locations: [...defaultLocations()]
    },
    {
      parentId: 23223,
      locations: [...defaultLocations()]
    },
    {
      parentId: 19999,
      locations: [
        {
          id: 222,
          city: "New York"
        }
      ]
    }
  ];
}

export function defaultPossibleLocations() {
  return {
    64333: [...defaultLocations()],
    23223: [...defaultLocations()],
    19999: [
      {
        id: 222,
        city: "New York"
      }
    ]
  };
}

export function defaultSpecialities() {
  return [
    { id: 157, name: "Fortune 500" },
    { id: 169, name: "Fortune 1000-2009" },
    { id: 170, name: "Fortune 500-2009" },
    { id: 172, name: "Sponsorship" },
    { id: 173, name: "Franchise" },
    { id: 174, name: "B2B" }
  ];
}

export function defaultIndustries() {
  return [
    { id: 1, name: "Industry1" },
    { id: 2, name: "Industry2" },
    { id: 3, name: "Industry3" },
    { id: 4, name: "Industry4" },
    { id: 5, name: "Industry5" },
    { id: 6, name: "Industry6" }
  ];
}

export function defaultDisciplines() {
  return [
    { id: 1, name: "Discipline1" },
    { id: 2, name: "Discipline2" },
    { id: 3, name: "Discipline3" },
    { id: 4, name: "Discipline4" },
    { id: 5, name: "Discipline5" },
    { id: 6, name: "Discipline6" }
  ];
}

export function prepareMappingDataWithValues() {
  return [
    {
      id: 1,
      field: "Id",
      value: "14921",
      dbTable: "businesses",
      dbDataField: "id",
      dbDataFields: []
    },
    {
      id: 2,
      field: "Company Name",
      value: "BMW of North America, LLC",
      dbTable: "businesses",
      dbDataField: "name",
      dbDataFields: []
    },
    {
      id: 3,
      field: "Website",
      value: "www.1800contacts.com",
      dbTable: "businesses",
      dbDataField: "website",
      dbDataFields: []
    },
    {
      id: 4,
      field: "Description",
      value:
        "Established in 1975, BMW of North America is a leading importer of luxury and performance vehicles.",
      dbTable: "businesses",
      dbDataField: "description",
      dbDataFields: []
    },
    {
      id: 5,
      field: "Revenue ($)",
      value: "30000000.00",
      dbTable: "businesses",
      dbDataField: "revenue",
      dbDataFields: []
    }
  ];
}

export const ERASE_BREADCRUMBS_FOR = [
  WinboxRoutePaths.FRONT_DASHBOARD,
  WinboxRoutePaths.COMPANIES,
  WinboxRoutePaths.AGENCIES,
  WinboxRoutePaths.BRANDS,
  WinboxRoutePaths.CONTACTS,
  WinboxRoutePaths.DATA_IMPORT,
  WinboxRoutePaths.SETTINGS,
  `${WinboxRoutePaths.SETTINGS}/${WinboxRoutePaths.SETTINGS_PROFILE}`,
  `${WinboxRoutePaths.SETTINGS}/${WinboxRoutePaths.SETTINGS_USERS}`,
  `${WinboxRoutePaths.SETTINGS}/${WinboxRoutePaths.SETTINGS_TASKS}`,
  WinboxRoutePaths.COMPANIES_DATA_MAPPING
];

export function updateBreadcrumbs(item) {
  let groupMatch = item.to.path.match(/(\w+)\/\d+\/(\w+)(\/(\w+))?/);

  if(groupMatch) {
    let strRegEx = `${groupMatch[1]}\/\\d+\/${groupMatch[2]}${groupMatch[4] != undefined ? `\/${groupMatch[4]}` : ''}`;
    let newRegEx = new RegExp(strRegEx);

    // If the current item exists in the breadcrumbs
    if(window.breadcrumbs.filter((breadcrumb) => breadcrumb.to.path.match(newRegEx)).length == 1) {
      while(window.breadcrumbs.filter((breadcrumb) => breadcrumb.to.path.match(newRegEx)).length == 1) {
        window.breadcrumbs = window.breadcrumbs.slice(0, -1);
      }
      window.breadcrumbs = [...window.breadcrumbs, item];
    }
    // If the current breadcrumb is not a profile
    else if(!item.to.path.match(/(?<!contacts)\/\d+\/profile/) && !item.to.path.includes('profile/title-functions')) {
      // If the previous breadcrumb was a contact profile then add an extra breadcrumb
      let previousBreadcrumb = window.breadcrumbs[window.breadcrumbs.length - 1].to.path;
      if(previousBreadcrumb.match(/(?<!contacts)\/\d+\/profile/) || previousBreadcrumb.includes('profile/title-functions')) {
        window.breadcrumbs = [...window.breadcrumbs, item];
      }
      // Otherwise, we need to replace the last breadcrumb with the current
      else {
        window.breadcrumbs[window.breadcrumbs.length - 1] = item;
      }
    }
    // If the current breadcrumb is a profile
    else if(item.to.path.match(/(?<!contacts)\/\d+\/profile/) || item.to.path.includes('profile/title-functions')) {
      window.breadcrumbs = [...window.breadcrumbs, item];
    }
  }
};

export function getBusinessLocationsTitle(business_locations) {
  if(typeof(business_locations) === "string") {
    return business_locations;
  }
  else if (typeof(business_locations) === "object") {
    return `${business_locations?.location_city}, ${business_locations?.location_state}`;
  }
  else if (business_locations && business_locations.length > 1) {
    return `${business_locations[0].location_city}, ${business_locations[0].location_state} and ${business_locations.length - 1} more`;
  }
};

export function getAgenciesPageUrl() {
  return `/agency-list`;
}

export function getCompaniesPageUrl() {
  return `/company-list`;
}

export function getBrandsPageUrl() {
  return `/brands-list`;
};

export function getPeoplePageUrl() {
  return `/people-list`;
};

export function getAgencyExceptByCompanyUrl(id) {
  return `/businesses/${id}/agency_except_by_business_relationship`;
};

export function getAgencyExceptByBrandUrl(id) {
  return `/brands/${id}/agency_except_by_brand_relationship`;
};

export function getBrandsExceptByAgencyUrl(id) {
  return `/agencies/${id}/brands_except_by_agency_relationship`;
};

export function getPersonAgencyBrandsUrl(id) {
  return `/api/people/${id}/brands-list?isAgency=1`;
};

export function getPersonBrandsUrl(id) {
  return `/api/people/${id}/brands-list`;
};

export function getOrganizationsSearchUrl() {
  return `/api/businesses?name=`;
};

export function getHierarchyOrganizationsSearchUrl(type) {
  return `/api/businesses?type=${type}&name=`;
};

export function getHierarchyBrandsSearchUrl() {
  return `/api/brands?name=`
};
