<template>
  <div class="winbox-add-relationships">
    <div v-if="!showNextStep" class="step-one">
      <winbox-server-data-table
        class="winbox-add-relationships-table"
        :url="url"
        :columns="columns"
        :options="tableOptions"
        :slots="specificSlots"
        :perPage="10"
        :responseFunction="enhanceData"
        theme="with-borders-theme"
        @row-click="onRowClick"
        ref="winboxDataTable"
      >
        <template :slot="tableProps.linked" slot-scope="data">
          <button
            class="button select-button"
            v-bind:class="{ active: data.row[tableProps.linked] }"
          >
            <winbox-icon
              icon="plus"
              :color="
                data.row[tableProps.linked]
                  ? Colors.system.lightGray
                  : Colors.system.primaryLight
              "
            />
          </button>
        </template>
      </winbox-server-data-table>
    </div>
    <div v-if="showNextStep && entity" class="step-two">
      <div class="columns">
        <div class="column">
          <winbox-input
            placeholder="Name"
            v-model.trim="$v.entity.name.$model"
            :readonly="true"
            :class="status($v.entity.name)"
          />
        </div>
      </div>

      <winbox-relation-ship-items
        :value="$v.entity"
        @input="onChangeEntityFields"
      />

      <div class="columns">
        <div class="column is-6">
          <winbox-select
            :value="entity.status"
            :options="possibleStatuses"
            :searchable="false"
            placeholder="Status"
            :class="status($v.entity.status)"
            @input="$v.entity.status.$model = $event"
          />
        </div>
        <div class="column is-6">
          <winbox-select
            v-if="possibleLocations.length >= 1"
            placeholder="Location"
            label="name"
            :class="status($v.entity.location)"
            v-model.trim="entity.location"
            :options="possibleLocations"
            :disabled="possibleLocations.length == 1"
            :emptyText="emptyText"
          />
          <winbox-input
            v-else
            placeholder="Location"
            :readonly="true"
            :value="emptyText"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <button
            class="button action-button"
            :class="{ 'is-primary': entity.is_aor }"
            @click="toggleAOR"
          >
            <input
              type="checkbox"
              :checked="entity.is_aor"
            />
            <span>AOR</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxServerDataTable from "@/components/Molecules/WinboxServerDataTable";

import WinboxRelationShipItems from "@/components/Molecules/WinboxRelationshipItems";

import cloneDeep from "clone-deep";
import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { required } from "vuelidate/lib/validators";
import { BusinessStatuses } from "@/constants/BusinessStatuses";

export default {
  name: "WinboxAddRelationshipsRemote",
  components: {
    WinboxIcon,
    WinboxInput,
    WinboxSelect,
    WinboxServerDataTable,
    WinboxRelationShipItems,
  },
  props: {
    config: {
      required: true,
      type: Object,
    },
    url: {
      required: true,
      type: String,
    },
    responseFunction: Function,
  },
  validations: {
    entity: {
      name: {
        required,
      },
      services: {
        required,
      },
      mediaTypes: {
        required: false
      },
      status: {
        required,
      },
      location: {
        required,
      },
    },
  },
  data() {
    return {
      entity: null,
      showNextStep: false,
      Colors,
      tableProps: this.config.tableProps,
      dataset: [],
      locationsData: [],
      columns: Object.values(this.config.tableProps),
      tableOptions: this.config.tableOptions,
      specificSlots: this.config.specificSlots,
      totalCount: 0,
    };
  },
  computed: {
    ...mapState([
      'currentAgency',
      'businessLocations',
    ]),
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
    possibleLocations() {
      return this.businessLocations;
    },
    emptyText() {
      if (this.$v.entity.$model) {
        return `Please make sure that ${this.isAgency ? this.currentAgency.name : this.$v.entity.$model.name} has a location`;
      }

      return "Sorry, no matching options.";
    },
    isAgency() {
      let path = this.$router.currentRoute.path.substr(1);
      path = path.match(/(\w+)\/\d+\/(\w+)(\/(\w+))?/);
      return path[1] == 'agencies';
    },
    agencyId() {
      let path = this.$router.currentRoute.path.substr(1);
      path = path.match(/(\d+)/);
      return path[0];
    },
  },
  methods: {
    ...mapActions([
      'getBusinessLocations',
    ]),
    toggleLinked(id) {
      if(this.isAgency) {
        this.getBusinessLocations(this.agencyId);
      }
      else {
        this.getBusinessLocations(id);
      }

      this.dataset.forEach((item) => {
        item[this.tableProps.linked] = false;
      });
      const found = this.dataset.find((item) => item.id === id);
      if (found) {
        found[this.tableProps.linked] = !found[this.tableProps.linked];
        this.entity = {
          ...found,
          status: this.possibleStatuses[0],
        };
        this.$refs.winboxDataTable.$refs.vTableRef.setData({
          data: { data: this.dataset, count: this.totalCount },
        });
      }
    },
    goToNextStep() {
      this.showNextStep = true;
    },
    getDeepCloneDataset() {
      return cloneDeep(this.dataset);
    },
    status(validation) {
      return Utils.validationStatus(validation);
    },
    onChangeEntityFields(e) {
      this.entity = {
        ...this.entity,
        ...e,
      };
    },
    onRowClick(e) {
      this.toggleLinked(e.row.id);
    },
    enhanceData(data, count) {
      this.totalCSount = count;
      this.dataset = this.responseFunction(data, count);
      return this.dataset;
    },
    toggleAOR() {
      if(this.entity.is_aor === undefined) {
        this.$set(this.entity, 'is_aor', true);
      }
      else {
        this.entity.is_aor = !this.entity.is_aor;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-add-relationships-table {
  ::v-deep {
    thead {
      th {
        vertical-align: middle;
        &:last-child {
          width: 130px;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
      }
      td {
        border-left: none;
        border-right: none;

        &:first-child {
          border-left: 1px solid $winbox-secondary-title;
        }
        &:last-child {
          border-right: 1px solid $winbox-secondary-title;
          text-align: right;
          padding-right: 1rem;
        }
      }
    }
    .select-button {
      border-color: $winbox-primary-active-bg-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 0;

      &.active {
        background: $winbox-primary-active-bg-color;
      }
      .icon {
        width: 1rem;
        height: 1rem;
      }
      .winbox-icon {
        font-size: $winbox-font-size-small;
      }
    }
  }
}
.action-button {
  @include profile-action-button;
}
</style>
